<template>
  <b-modal
    ref="modalVoidTransaction"
    v-model="isShowModal"
    centered
    @show="show"
    @hidden="hide"
  >
    <template #modal-header>
      <div class="ft-black">Void/Return</div>
    </template>
    <div>
      <div>
        <InputText
          textFloat="Ref.Void/Return Bill"
          placeholder="Ref.Void/Return Bill"
          type="number"
          name="bill_void"
          v-model="form.invoice_no"
          :v="v.formVoid.invoice_no"
          :isValidate="v.formVoid.invoice_no.$error"
          @onKeyup="onKeyupAddZero"
          :disabled="form.is_fullpos == 1 ? true : false"
        />
      </div>
      <div>
        <InputTextArea
          :rows="4"
          textFloat="Additional Notes (if any)"
          placeholder="Additional Notes"
          id="textarea-rows"
          v-model="form.note"
        />
      </div>

      <div class="d-flex align-items-center">
        <b-form-checkbox
          v-model="form.is_return_inventory"
          :unchecked-value="0"
          :value="1"
          :disabled="form.is_top_up_credit"
        >
          Return to inventory
        </b-form-checkbox>
        <InputSelect
          :disabled="!form.is_return_inventory"
          class="mb-0 ml-2"
          :options="stockList"
          v-model="form.stock_type_id"
          valueField="id"
          textField="name"
          style="width: 66%"
        >
          <template v-slot:option-first>
            <option :value="0" disabled>Select Return Stock</option>
          </template>
        </InputSelect>
      </div>
      <div>
        <b-form-checkbox
          v-model="form.is_return_store_credit"
          :disabled="
            !form.is_store_credit || form.void_return_to_store_credit == 0
          "
          :unchecked-value="false"
          :value="true"
        >
          Return Store Credit
        </b-form-checkbox>
      </div>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col>
          <b-button class="btn-cancel" @click="hide"> No </b-button>
        </b-col>
        <b-col>
          <b-button
            @click.prevent="VoidTransaction"
            class="btn-confirm"
            :disabled="disableBtnVoid"
          >
            Yes
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    InputText,
    InputTextArea,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    disableBtnVoid: {
      required: true,
      type: Boolean,
    },
    invoiceLength: {
      required: false,
      type: Number,
    },
    transactionDetail: {
      required: false,
    },
  },
  data() {
    return {
      isShowModal: false,
      stockList: [],
    };
  },
  watch: {
    "form.is_return_inventory"(val) {
      if (val) {
        this.getStockList();
        this.form.stock_type_id = 1;
      } else if(val == 0 && this.isShowModal) {
        this.warningAlert(
          "Product/Serial จะไม่ถูกคืนกลับสู่ Inventory",
          "คำเตือน",
          "ยืนยัน"
        );
        this.form.stock_type_id = 0;
      }
    },
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
      this.$emit("clearForm");
    },
    async getStockList(id) {
      const res = await this.axios.get("/inventory/GetActiveMasterStockType");

      this.stockList = res.data.detail;
    },
    onKeyupAddZero(val) {
      let zero = new Array(this.invoiceLength + 1).join("0");

      this.form.invoice_no = (zero + this.form.invoice_no).slice(
        -this.invoiceLength
      );
    },

    VoidTransaction() {
      this.v.formVoid.$touch();
      if (this.v.formVoid.$error) {
        return;
      }
      this.$emit("confirmVoidTransaction", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
